import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles(theme => {
  return {
    root: {
      maxWidth: 1128,
      paddingTop: 180,
      paddingBottom: 180,
      [theme.breakpoints.down("md")]: {
        paddingTop: 120,
        paddingBottom: 120,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 70,
        paddingBottom: 70,
      },
    },
    title: {
      textAlign: "center",
    },
    content: {
      marginTop: 60,
    },
  }
})

const About = () => {
  const classes = useStyles()

  const {
    datoCmsHomePage: { aboutContent },
  } = useStaticQuery(
    graphql`
      query {
        datoCmsHomePage {
          aboutContent
        }
      }
    `
  )

  return (
    <Container className={classes.root} id="onas">
      <Typography component="h1" variant="h1" className={classes.title}>
        O NAS
      </Typography>
      <Typography
        component="article"
        variant="body1"
        className={classes.content}
      >
        <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
      </Typography>
    </Container>
  )
}

export default About
