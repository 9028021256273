import React from "react"
import map from "lodash/map"
import slice from "lodash/slice"
import kebabCase from "lodash/kebabCase"
import truncate from "lodash/truncate"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { FacebookShareButton } from "react-share"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"

import FbIcon from "../../images/fb.svg"
import BlogIcon from "../../images/blog-icon.svg"
import getNornalizeDate from '../../helpers/getNornalizeDate'

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    paddingBottom: 100,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 0,
    },
  },
  container: {},
  titleContainer: {
    display: "flex",
    marginTop: 50,
    justifyContent: "center",
  },
  title: {
    color: theme.palette.text.disabled,
  },
  grid: {
    "&.container:not(:first-child)": {
      marginTop: 120,
    },
  },
  gridItem: {
    display: "flex",
    margin: 0,
    padding: "140px 20px",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    transition: "background-color 200ms linear",
    "&:hover": {
      backgroundColor: "hsl(0 0% 0% / 0.025)",
    },
    "&:hover:active": {
      backgroundColor: "hsl(0 0% 0% / 0.05)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0",
    },
  },
  test: {
    color: theme.palette.text.disabled,
  },
  postTitle: {
    color: "#363654",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  postContent: {
    color: "#363654",
  },
  iconContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "flex-end",
  },
  icon: {
    bottom: "-170px",
    position: "relative",
    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "50%",
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      top: 25,
      width: 60,
      height: 60,
    },
  },
  link: {
    color: "initial",
    textDecoration: "none",
    "&:hover": {
      color: "initial",
      textDecoration: "underline",
    },
  },
  linkBtn: {
    color: theme.palette.text.disabled,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkBtnText: {
    letterSpacing: "5px",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      letterSpacing: "4px",
      fontSize: "18px",
    },
  },
  gatsbyImage: {},
  imageContainer: {
    width: "100%",
  },
  gatsbyBgImage: {
    maxHeight: 600,
  },
  postData: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  postMeta: {},
}))

const Blog = props => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const { allDatoCmsBlogPage, datoCmsHomePage } = useStaticQuery(
    graphql`
      query {
        allDatoCmsBlogPage(sort: {fields: blogDate, order: DESC}) {
          nodes {
            blogTitle
            blogTeaser
            blogDate
            blogImage {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            }
          }
        }
        datoCmsHomePage {
          blogBgImage {
            gatsbyImageData
          }
        }
      }
    `
  )

  const blogItems = slice(
    map(allDatoCmsBlogPage.nodes, node => ({
      title: node.blogTitle,
      image: getImage(node.blogImage.gatsbyImageData),
      date: getNornalizeDate(node.blogDate),
      teaser: node.blogTeaser,
      content: node.blogContent,
    })),
    0,
    4
  )

  const slicedBlogItems = slice(blogItems, 0, isMobile ? 2 : 4)

  const bgImage = getImage(datoCmsHomePage.blogBgImage)

  return (
    <Box className={classes.root} id="blog">
      <GatsbyImage
        image={bgImage}
        alt="Blog"
        className={classes.gatsbyBgImage}
      />
      <Container className={classes.titleContainer} maxWidth="xl">
        <Typography variant="h1" component="h1" className={classes.title}>
          Blog
        </Typography>
      </Container>
      <Container className={classes.container} maxWidth="xl">
        <Grid container className={classes.grid}>
          {map(slicedBlogItems, ({ title, teaser, image, date }, index) => {
            const blogTeaser = truncate(teaser, { length: 110 })
            const blogTitle = truncate(title, { length: 40 })
            const fburl = `${props.location.href}${kebabCase(title)}`

            return (
              <Grid
                key={index}
                item
                container
                className={classes.gridItem}
                spacing={3}
              >
                <Grid item md={6} sm={12} className={classes.imageContainer}>
                  <Link
                    to={`/blog/${kebabCase(title)}`}
                    className={classes.link}
                  >
                    <GatsbyImage
                      image={image}
                      alt="Hero image"
                      className={classes.gatsbyImage}
                    />
                  </Link>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Link
                    to={`/blog/${kebabCase(title)}`}
                    className={classes.link}
                  >
                    <Typography variant="h2" className={classes.postTitle}>
                      {blogTitle}
                    </Typography>
                  </Link>
                  <Box
                    my={2}
                    display="flex"
                    alignItems="center"
                    className={classes.postMeta}
                  >
                    <Typography variant="body2" className={classes.postData}>
                      {date}
                    </Typography>
                    <FacebookShareButton url={fburl}>
                      <FbIcon />
                    </FacebookShareButton>
                  </Box>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.postContent}
                    dangerouslySetInnerHTML={{ __html: blogTeaser }}
                  />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        <Box display="flex" justifyContent="center" mt={9}>
          <Link to="/blog/" className={classes.linkBtn}>
            <Typography variant="h1" className={classes.linkBtnText}>
              Zobacz Wszystkie
            </Typography>
          </Link>
        </Box>
        <Box className={classes.iconContainer}>
          <BlogIcon className={classes.icon} />
        </Box>
      </Container>
    </Box>
  )
}

export default Blog
