import React from "react"
import map from "lodash/map"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  gridItem: {
    padding: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 20px",
    },
  },
  list: {
    color: theme.palette.text.secondary,
    fontSize: "25px",
    fontWeight: 400,
    "@media (max-width: 599px)": {
      fontSize: "18px",
      "& ul": {
        paddingLeft: 25,
      },
    },
  },
  author: {
    color: theme.palette.text.disabled,
    display: "block",
    textAlign: "right",
    fontWeight: "bold",
  },
  role: {
    color: theme.palette.text.disabled,
    display: "block",
    textAlign: "right",
    fontWeight: "bold",
  },
}))

export default function RecommendationDesktop({ recommendations }) {
  const classes = useStyles()

  return map(recommendations, ({ id, text, author, role }) => (
    <Grid item sm={12} md={6} key={id} className={classes.gridItem}>
      <Box
        className={classes.list}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <Typography variant="h4" className={classes.author}>
        {author}
      </Typography>
      <Typography variant="h4" className={classes.role}>
        {role}
      </Typography>
    </Grid>
  ))
}
