import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import scrollTo from "gatsby-plugin-smoothscroll"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import ArrowIcon from "../../components/icons/ArrowIcon"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  heading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  subHeading: {
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      fontWeight: "400",
      marginBottom: 40,
    },
  },
  textContainer: {
    bottom: "30%",
    maxWidth: 700,
    position: "absolute",
    textAlign: "center",
    textShadow: "2px 2px 5px rgb(0, 0, 0, 0.4)",
    [theme.breakpoints.down("sm")]: {
      left: 0,
      right: 0,
      margin: 20,
      bottom: "20%",
    },
  },
  button: {
    marginTop: "75px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      marginTop: "50px",
    },
  },
  buttonScroll: {
    left: 0,
    right: 0,
    width: 40,
    bottom: 120,
    margin: "auto",
    filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.6))",
    cursor: "pointer",
    position: "absolute",
    boxShadow: "none",
    background: "transparent !important",
    [theme.breakpoints.down("xs")]: {
      bottom: 20,
    },
  },
  gatsbyImageMobile: {
    display: "block",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        objectPosition: "right top",
      },
    },
  },
  gatsbyImage: {
    display: "none",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        objectPosition: "right top",
      },
    },
  },
}))

export default function Hero() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const { datoCmsHomePage } = useStaticQuery(graphql`
    query {
      datoCmsHomePage {
        heroTitle1
        heroTitle2
        heroButtonText
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            aspectRatio: 1.77777777778
          )
        }
        heroImageMobile {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  const handleClick = () => {
    return scrollTo(`#kontakt`, "start")
  }

  const {
    heroButtonText,
    heroTitle1,
    heroTitle2,
    heroImage,
    heroImageMobile,
  } = datoCmsHomePage
  const href = isMobile ? "tel:+48795995368" : null

  return (
    <>
      <Box className={classes.root} id="home">
        <GatsbyImage
          image={getImage(heroImage)}
          alt="Hero image"
          className={classes.gatsbyImage}
        />
        <GatsbyImage
          image={getImage(heroImageMobile)}
          alt="Hero image"
          className={classes.gatsbyImageMobile}
        />
        <Container maxWidth="xl">
          <Box className={classes.textContainer}>
            <Typography
              color="textSecondary"
              variant="h2"
              component="h2"
              gutterBottom
              className={classes.subHeading}
            >
              {heroTitle1}
            </Typography>
            <Typography color="textSecondary" component="h1" variant="h1">
              {heroTitle2}
            </Typography>

            <Button
              variant="contained"
              href={href}
              className={classes.button}
              onClick={() => (isMobile ? null : handleClick())}
            >
              {heroButtonText}
            </Button>
          </Box>
        </Container>
        <ArrowIcon
          className={classes.buttonScroll}
          onClick={() => scrollTo("#onas", "start")}
        />
      </Box>
    </>
  )
}
