import React from "react"

const ArrowIcon = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 116.72 68.24"
    className={className}
    onClick={onClick}
  >
    <path d="M113.8 2.94a10 10 0 0 0-14.14 0l-41.3 41.2-41.3-41.22A10 10 0 0 0 2.94 17.08L51.3 65.32c.16.16.33.31.5.45a2.73 2.73 0 0 0 .25.23c.17.14.35.26.54.39a2.69 2.69 0 0 0 .27.2c.18.12.37.23.56.34l.29.17.61.28.28.13.62.22.29.1.65.16.29.07.69.1a2.3 2.3 0 0 0 .26 0 9.24 9.24 0 0 0 1.92 0l.43-.06.52-.07c.17 0 .33-.08.49-.12l.45-.11.49-.17.42-.15.47-.22.41-.16.41-.25a3.91 3.91 0 0 0 .44-.26c.12-.07.23-.17.34-.25s.32-.22.47-.34l.28-.25c.16-.14.32-.28.47-.43l48.36-48.24a10 10 0 0 0 .03-14.14z" />
  </svg>
)

ArrowIcon.defaultProps = {
  className: "",
}

export default ArrowIcon
