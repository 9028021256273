import React, { useState } from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import * as Yup from "yup"
import Snackbar from "@material-ui/core/Snackbar"
import RoomIcon from "@material-ui/icons/Room"
import Container from "@material-ui/core/Container"
import TextField from "@material-ui/core/TextField"
import MuiTypography from "@material-ui/core/Typography"
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Field, Form, Formik } from "formik"

import ShapeIcon from "../../images/shape.svg"

const Typography = withStyles({
  colorTextSecondary: {
    "@media (max-width: 599px)": {
      fontSize: "16px",
    },
  },
})(MuiTypography)

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    position: "relative",
    background: "linear-gradient(0deg, #677BF8 0%, #866EE8 100%)",
    paddingTop: 180,
    paddingBottom: 100,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 100,
    },
  },
  container: {
    maxWidth: 1128,
  },
  contentContainer: {
    marginTop: 200,
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
    },
  },
  title: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
    },
  },
  content: {
    marginTop: 60,
  },
  field: {
    margin: theme.spacing(2),
    borderRadius: "15px",
    backgroundColor: "hsl(0deg 0% 100% / 65%)",
  },
  iframe: {
    margin: "100px 0",
  },
  classesInput: {
    color: "#fff",
    margin: "15px 0",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    "& input": {
      padding: "20px 12px",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 12px",
        fontSize: "18px",
      },
    },
    "& textarea": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
    "& .Mui-error": {
      fontSize: "1.3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
  },
  iframeContainer: {
    border: "none",
    borderRadius: "10px",
  },
  submitButton: {
    color: "#fff",
    fontSize: "25px",
    borderRadius: "15px",
    backgroundColor: "transparent",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  icons: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1),
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  iconPin: {
    marginTop: 5,
    alignItems: "flex-start",
  },
  companyName: {
    lineHeight: "2rem",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "1.5rem",
    },
  },
  shapeIcon: {
    right: "50%",
    bottom: "-510px",
    height: "110%",
    position: "absolute",
    transform: "translateX(100%)",
    [theme.breakpoints.down("xs")]: {
      height: "300%",
      bottom: "-1400px",
    },
  },
  alert: {
    color: "#4caf50 !important",
    fontSize: "1rem",
  },
}))

const INITIAL_VALUES = {
  name: "",
  phone: "",
  email: "",
  note: "",
}


const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const useStylesInput = makeStyles(theme => ({
  root: {
    color: "#fff",
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    fontSize: "25px",
    borderRadius: 15,
    opacity: 1,
    backgroundColor: "rgba(255,255,255,.65) !important",

    "&:hover": {
      color: "#fff",
    },
    "&$focused": {
      color: "#fff",
    },
  },
  focused: {},
}))

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Pole jest za krótkie")
    .max(50, "Pole jest za długie")
    .required("Wymagane"),
  phone: Yup.string()
    .min(3, "Pole jest za krótkie")
    .max(15, "Pole jest za długie"),
  email: Yup.string().email("Email jest nieprawidłowy").required("Wymagane"),
  note: Yup.string()
    .min(2, "Pole jest za krótkie")
    .max(300, "Pole jest za długie")
    .required("Wymagane"),
})

const Contact = () => {
  const classes = useStyles()
  const classesInput = useStylesInput()
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const handleSubmit = ({ name, phone, email, note }, actions) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Denaro formularz kontaktowy",
        name,
        phone,
        email,
        note
      }),
    })
      .then(response => {
        if (response.ok) {
          setOpen(true)
          actions.resetForm()
        }
      })
      .catch(error => {
        console.log("Error", { error })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Box className={classes.root} id="kontakt" component="div">
      <ShapeIcon className={classes.shapeIcon} />
      <Container className={classes.container}>
        <Typography
          color="textSecondary"
          variant="h1"
          component="h1"
          className={classes.title}
        >
          Kontakt
        </Typography>

        <Grid container spacing={3} className={classes.contentContainer}>
          <Grid item sm={12} md={6}>
            <Typography
              gutterBottom
              variant="body1"
              color="textSecondary"
              className={classes.companyName}
            >
              Kancelaria Podatkowo-Rachunkowa
              <br />
              Denaro Sp.zo.o.
            </Typography>
            <Typography
              component="div"
              gutterBottom
              variant="body1"
              color="textSecondary"
            >
              <Box mt={1} display="flex" alignItems="flex-start">
                <RoomIcon className={`${classes.icons} ${classes.iconPin}`} />
                <Box>
                  ul. Radarowa 9/100 <br />
                  02-137 Warszawa
                </Box>
              </Box>
              <Box mt={1} display="flex" alignItems="center">
                <PhoneIphoneIcon className={classes.icons} />
                <a href="tel:+48795995368" className={classes.link}>
                  +48 795 995 368
                </a>
              </Box>
              <Box mt={1} display="flex" alignItems="center">
                <MailOutlineIcon className={classes.icons} />
                <a
                  href="mailto:kontakt@kancelariadenaro.com"
                  className={classes.link}
                >
                  kontakt@kancelariadenaro.com
                </a>
              </Box>
            </Typography>

            <Box mt={5} display="flex" justifyContent="flex-start">
              <Typography gutterBottom variant="body1" color="textSecondary">
                Numer NIP: 5252511642
                <br />
                Numer KRS: 0000391471
                <br />
                <a href="//www.kancelariadenaro.com" className={classes.link}>
                  www.kancelariadenaro.com
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={INITIAL_VALUES}
              validationSchema={ContactSchema}
            >
              {props => {
                return (
                  <Form
                    name="Denaro formularz kontaktowy"
                    data-netlify={true}
                    netlify-honeypot="bot-field"
                  > 
                    <div style={{ display: "none" }}>
                      <label>
                        Don’t fill this out: <input name="bot-field" />
                      </label>
                    </div>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <Field name="name" autoComplete="off">
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              label={null}
                              error={Boolean(form.errors[field.name])}
                              placeholder="Imię i nazwisko"
                              variant="filled"
                              fullWidth
                              className={classes.classesInput}
                              helperText={form.errors[field.name]}
                              InputProps={{
                                classes: classesInput,
                                disableUnderline: true,
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Field name="phone">
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              error={Boolean(form.errors[field.name])}
                              placeholder="Numer telefonu"
                              variant="filled"
                              fullWidth
                              className={classes.classesInput}
                              helperText={form.errors[field.name]}
                              InputProps={{
                                classes: classesInput,
                                disableUnderline: true,
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="email">
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              error={Boolean(form.errors[field.name])}
                              placeholder="Adres email"
                              variant="filled"
                              fullWidth
                              className={classes.classesInput}
                              helperText={form.errors[field.name]}
                              InputProps={{
                                classes: classesInput,
                                disableUnderline: true,
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="note">
                          {({ field, form }) => (
                            <TextField
                              {...field}
                              rows={5}
                              placeholder="Wiadomość"
                              error={Boolean(form.errors[field.name])}
                              variant="filled"
                              fullWidth
                              multiline
                              className={classes.classesInput}
                              InputProps={{
                                classes: classesInput,
                                disableUnderline: true,
                              }}
                              helperText={form.errors[field.name]}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Button
                        size="large"
                        type="submit"
                        disabled={
                          !(props.isValid && props.dirty) || props.isSubmitting
                        }
                        className={classes.submitButton}
                        startIcon={
                          props.isSubmitting && (
                            <CircularProgress color="inherit" size={20} />
                          )
                        }
                      >
                        Wyślij
                      </Button>
                    </Box>
                    <Snackbar
                      open={open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert
                        severity="success"
                        className={classes.alert}
                        action={null}
                      >
                        Wiadomość została wysłana, dziękujemy.
                      </Alert>
                    </Snackbar>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
        </Grid>
        <Box className={classes.iframe}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d724.6518446431103!2d20.966141016514985!3d52.190864300889196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4719334f43679097%3A0xeb51cc0bc3bbe588!2sRadarowa%209%2C%2002-156%20Warszawa!5e0!3m2!1spl!2spl!4v1617136089375!5m2!1spl!2spl"
            title="map"
            width="100%"
            height="450"
            loading="lazy"
            className={classes.iframeContainer}
            allowFullScreen=""
          ></iframe>
        </Box>
      </Container>
    </Box>
  )
}

export default Contact
