import React, { useState, useEffect } from "react"
import cx from "classnames"
import map from "lodash/map"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"
import MuiPaper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import OfferIcon from "../../images/offer-icon.svg"

const Paper = withStyles(theme => ({
  rounded: {
    borderRadius: 40,
  },
}))(MuiPaper)

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    paddingTop: 736,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 400,
    },
  },
  container: {
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translateY(50%)",
    maxWidth: "40vw",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    marginTop: 20,
    "@media (max-width: 1120px)": {
      fontSize: "20px",
      marginTop: "15px",
    },
  },
  button: {
    margin: "70px auto 0",
    "@media (max-width: 1120px)": {
      padding: "5px 40px",
      fontSize: "15px",
      marginTop: 40,
    },
  },
  imageContainer: {
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 0,
    height: 736,
    position: "absolute",
    "& img": {
      objectPosition: "right center",
    },

    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
    [theme.breakpoints.down("xs")]: {
      height: 400,
      backgroundColor: "#8771ef",
    },
  },
  image: {
    height: "100%",
    maxHeight: 736,
  },
  list: {
    color: "#363654",
  },
  icon: {
    top: "-180px",
    position: "relative",
    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      top: "-155px",
      width: 100,
      height: 100,
    },
  },
  itemTitle: {
    color: "#fff",
    minHeight: 186,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: theme.palette.secondary.main,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      minHeight: 96,
    },
  },
  itemTitleText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  itemTitleList: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
  },
  itemContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    "& table": {
      width: "100%",
      border: "none",
    },
    "& tr:first-child td": {
      padding: "6px 4px",
    },
    "& tr": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    "& td": {
      border: "none",
      padding: "6px 6px 6px 22px",
    },
  },
  itemContentTable: {
    "& tr:first-child": {
      color: theme.palette.text.disabled,
      "& strong": {
        fontWeight: "600",
      },
    },
  },
  prevBtn: {
    top: 0,
    left: 20,
    width: 80,
    height: 80,
    zIndex: 10,
    bottom: 0,
    margin: "auto",
    position: "absolute",
  },
  nextBtn: {
    top: 0,
    right: 20,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    zIndex: 10,
    height: 80,
    width: 80,
  },
  offerTextContainer: {
    top: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    padding: "0 30px",
    display: "flex",
    position: "absolute",
    maxWidth: "40vw",
    flexFlow: "column nowrap",
    textAlign: "center",
    justifyContent: "center",
    "@media (max-width: 1400px)": {
      maxWidth: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
      "& p": {
        fontSize: "14px",
        marginTop: "10px",
        lineHeight: 1.4,
      },
      "& button": {
        padding: "5px 40px",
        fontSize: "15px",
        marginTop: 20,
      },
    },
  },
  carouselContainer: {
    marginTop: 100,
    marginBottom: 200,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 140,
    },
  },
  listContainer: {
    maxWidth: 1128,
    marginTop: 160,
    marginBottom: 60,
    [theme.breakpoints.down("xs")]: {
      marginTop: 80,
    },
  },
  sectionIcon: {
    top: "-80px",
    right: 40,
    position: "absolute",
    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "50%",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
      top: "-55px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "-35px",
      width: 60,
      height: 60,
    },
  },
  itemContainer: {},
}))

const Offer = () => {
  const theme = useTheme()
  const classes = useStyles()
  const [slidesToShow, setSlidesToShow] = useState(3)

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const { datoCmsHomePage } = useStaticQuery(graphql`
    query {
      datoCmsHomePage {
        offerTitle
        offerSubtitle
        offerButton
        offerContent
        offerEmail
        carousel {
          ... on DatoCmsOfertaTypeText {
            id
            offerTitle
            offerOrder
            offerType
            offerContent
          }
          ... on DatoCmsOfertaTypeTable {
            id
            offerTitle
            offerOrder
            offerType
            offerContent
          }
          ... on DatoCmsOfferTypeList {
            id
            offerTitle
            offerOrder
            offerType
            offerContent
          }
        }
      }
    }
  `)

  const {
    carousel,
    offerEmail,
    offerTitle,
    offerButton,
    offerContent,
    offerSubtitle,
  } = datoCmsHomePage

  const carouselItems = map(carousel, item => ({
    id: item.id,
    title: item.offerTitle,
    order: item.offerOrder,
    type: item.offerType,
    content: item.offerContent,
  }))

  useEffect(() => {
    if (isMobile) {
      setSlidesToShow(1)
      return
    }
    if (isTablet || isDesktop) {
      setSlidesToShow(2)
      return
    }
  }, [isMobile, isTablet, isDesktop])

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <IconButton
      {...props}
      className={classes.prevBtn}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <ChevronLeftIcon fontSize="large" />
    </IconButton>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <IconButton
      {...props}
      className={classes.nextBtn}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <ChevronRightIcon fontSize="large" />
    </IconButton>
  )

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    variableWidth: true,
    centerPadding: isDesktop ? "60px 10px" : "40px 10px",
    slidesToShow,
    speed: 300,
    arrows: isDesktop,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }

  const mailTo = offerEmail ? `mailto:${offerEmail}` : null

  return (
    <Box className={classes.root} id="oferta">
      <div className={classes.imageContainer}>
        <OfferIcon className={classes.sectionIcon} />
        <Hidden xsDown>
          <StaticImage
            src="../../images/oferta.png"
            layout="fullWidth"
            alt="Oferta"
            className={classes.image}
          />
        </Hidden>
        <Box className={classes.offerTextContainer}>
          <Typography
            color="textSecondary"
            component="h1"
            variant="h1"
            className={classes.title}
          >
            {offerTitle}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            className={classes.subtitle}
          >
            {offerSubtitle}
          </Typography>

          <Button href={mailTo} variant="contained" className={classes.button}>
            {offerButton}
          </Button>
        </Box>
      </div>

      <Container className={classes.listContainer}>
        <Typography
          component="div"
          className={classes.list}
          dangerouslySetInnerHTML={{ __html: offerContent }}
        />
      </Container>
      <Box className={classes.carouselContainer}>
        <Slider {...settings} className="slider-offer">
          {map(carouselItems, ({ id, title, content, type }) => {
            return (
              <Box
                px={isDesktop ? 6 : 1}
                key={id}
                className={classes.itemContainer}
              >
                <Paper
                  className={cx({
                    [classes.itemTitleList]: type === "Lista",
                  })}
                >
                  <Box className={classes.itemTitle}>
                    <Typography
                      variant="h2"
                      component="div"
                      className={classes.itemTitleText}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </Box>
                  <Box
                    component="div"
                    className={cx(classes.itemContent, {
                      [classes.itemContentTable]: type === "Tabela",
                    })}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </Paper>
              </Box>
            )
          })}
        </Slider>
      </Box>
    </Box>
  )
}

export default Offer
