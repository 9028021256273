import React from "react"
import get from "lodash/get"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Hero from "../components/hero"
import Blog from "../components/blog"
import Offer from "../components/offer"
import About from "../components/about"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Recommendations from "../components/recommendations"

const IndexPage = props => {
  const { title, description } = get(props, "data.datoCmsHomePage.seo", {})

  return (
    <Layout {...props}>
      <Seo title={title} description={description} />
      <Hero />
      <About />
      <Offer />
      <Recommendations />
      <Blog {...props} />
      <Contact location={props.location} />
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsHomePage {
      seo {
        description
        title
      }
    }
  }
`

export default IndexPage
