import React from "react"
import map from "lodash/map"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import RecommendationMobile from "./RecommendationMobile"
import RecommendationDesktop from "./RecommendationDesktop"
import RecommendationsIcon from "../../images/recommendations-icon.svg"
import ShapeIcon from "../../images/shape.svg"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    background: "linear-gradient(0deg, #677BF8 0%, #866EE8 100%)",
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  container: {},
  title: {
    textAlign: "center",
  },
  grid: {
    marginTop: 120,
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
    },
  },
  icon: {
    top: "-180px",
    position: "relative",
    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      top: "-35px",
      width: 60,
      height: 60,
    },
  },
  shapeIcon: {
    left: "50%",
    height: "100%",
    bottom: "-400px",
    position: "absolute",
    transform: "translateX(-100%)",
    [theme.breakpoints.down("xs")]: {
      height: "120%",
      bottom: "-270px",
    },
  },
}))

const Recommendations = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { allDatoCmsRecommendation } = useStaticQuery(
    graphql`
      query {
        allDatoCmsRecommendation {
          nodes {
            id
            recommendationsText
            recommendationsRole
            recommendationsAuthor
          }
        }
      }
    `
  )

  const enhancedRecommendations = map(allDatoCmsRecommendation.nodes, node => ({
    id: node.id,
    role: node.recommendationsRole,
    text: node.recommendationsText,
    author: node.recommendationsAuthor,
  }))

  return (
    <Box className={classes.root} id="recommendations">
      <ShapeIcon className={classes.shapeIcon} />
      <Container className={classes.container} maxWidth="xl">
        <RecommendationsIcon className={classes.icon} />
        <Typography
          color="textSecondary"
          component="h1"
          variant="h1"
          className={classes.title}
        >
          Rekomendacje
        </Typography>
        <Grid container className={classes.grid}>
          {isMobile ? (
            <RecommendationMobile recommendations={enhancedRecommendations} />
          ) : (
            <RecommendationDesktop recommendations={enhancedRecommendations} />
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default Recommendations
