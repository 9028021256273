import React from "react"
import map from "lodash/map"
import Box from "@material-ui/core/Box"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  container: {
    margin: "0 auto",
    display: "flex !important",
    maxWidth: 500,
    flexFlow: "column",
  },
  title: {
    color: "#fff",
  },
  content: {
    color: "#fff",
    marginBottom: 40,
  },
  role: {
    display: "flex",
    fontWeight: "bold",
    justifyContent: "flex-end",
    color: theme.palette.text.disabled,
  },
  author: {
    display: "flex",
    fontWeight: "bold",
    justifyContent: "flex-end",
    color: theme.palette.text.disabled,
  },
  prevBtn: {
    top: 0,
    left: 20,
    width: 80,
    height: 80,
    zIndex: 10,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      left: "-20px",
    },
  },
  nextBtn: {
    top: 0,
    right: 20,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    zIndex: 10,
    height: 80,
    width: 80,
    [theme.breakpoints.down("xs")]: {
      right: "-20px",
    },
  },
}))

export default function RecommendationMobile({ recommendations }) {
  const classes = useStyles()

  const settings = {
    speed: 300,
    arrows: false,
    infinite: true,
    autoplay: true,
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    centerPadding: 0,
    variableWidth: true,
  }

  return (
    <Box className={classes.root}>
      <Slider {...settings} className="slider-recomenndation">
        {map(recommendations, ({ role, text, author, id }) => (
          <Box p={2} key={id} className={classes.container}>
            <Box
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <Box className={classes.author}>{author},</Box>
            <Box className={classes.role}>{role}</Box>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}
